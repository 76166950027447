import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { between } from 'polished';
import sanitizeHtml from 'sanitize-html';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaQuestionCircle, FaWhatsapp } from 'react-icons/fa';
import * as html2text from 'html2plaintext';

import Page from '../components/page/page';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import Navigation from '../components/navigation';
import Container from '../components/container';
import SEO from '../components/seo';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('120px', '300px')};
`;

const Title = styled.h1`
	font-size: ${between('26px', '50px')};

	&::after {
		content: '';
		background: ${props => props.theme.colors.golden_500};
		width: ${between('50px', '70px')};
		height: ${between('1px', '2px')};
		display: block;
		z-index: -1;
		margin-top: ${between('10px', '18px')};
	}
`;

const Wrapper = styled.div`

`;

const Meta = styled.div`
	margin-bottom: 23px;

	ul {
		list-style-type: none;

		li {
			display: flex;
			padding-bottom: 8px;
			border-bottom: dashed 1px ${props => props.theme.colors.warm_grey_200};
			align-items: baseline;

			& + li {
				margin-top: 23px;
			}

			.label {
				margin-right: auto;
				font-size: 10px;
				font-weight: 700;
				letter-spacing: 1px;
				text-transform: uppercase;
			}

			.value {
				text-align: right;
			}

			.hint svg {
				color: ${props => props.theme.colors.warm_grey_300};
				cursor: help;
				font-size: 14px;
				margin-left: 5px;
			}

			[tooltip]::after {
				transform: translateX(-90%) translateY(-100%);
			}

			.messenger,
			.whatsapp {
				color: ${props => props.theme.colors.white};
				font-size: 14px;
				padding: 7px 16px 6px;
				border-radius: 5px;
				transition: all 0.3s;

				&:hover,
				&:focus {
					text-decoration: none;
					outline: none;
				}
			}

			.messenger {
				background: ${props => props.theme.colors.messenger_100};
				background: linear-gradient(to bottom, ${props => props.theme.colors.messenger_100} 0%, ${props => props.theme.colors.messenger_500} 100%);

				svg {
					width: 20px;
					height: 20px;
				}

				&:hover,
				&:focus {
					background: linear-gradient(to bottom, ${props => props.theme.colors.messenger_500} 0%, ${props => props.theme.colors.messenger_1000} 100%);
				}
			}

			.whatsapp {
				background: ${props => props.theme.colors.whatsapp_100};
				background: linear-gradient(to bottom, ${props => props.theme.colors.whatsapp_100} 0%, ${props => props.theme.colors.whatsapp_500} 100%);
				font-size: 20px;
				padding-bottom: 5px;

				svg {
					fill: ${props => props.theme.colors.white};
				}

				&:hover,
				&:focus {
					background: linear-gradient(to bottom, ${props => props.theme.colors.whatsapp_500} 0%, ${props => props.theme.colors.whatsapp_1000} 100%);
				}
			}

			a + a {
				margin-left: 7px;
			}
		}
	}

	@media (min-width: ${props => props.theme.breakpoints.md}) {
		background: ${props => props.theme.colors.warm_grey_100};
		width: 350px;
		padding: 23px;
		float: right;
		position: sticky;
		top: 23px;
		border-radius: 2px;
		z-index: 10;
	}
`;

const Content = styled.div`
	@media (min-width: ${props => props.theme.breakpoints.md}) {
		width: calc(100% - 350px - 23px);
	}
`;

const Gallery = styled.div`
	.control-arrow {
		padding: 23px !important;
	}

	.single {
		width: 100%;
	}

	.carousel .slide {
		background: ${props => props.theme.colors.white};
	}
`;

const Description = styled.div`
	line-height: ${between('22px', '26px')};
	word-break: break-word;

	* + * {
		margin-top: 23px;
	}

	h2 {
		font-size: ${between('20px', '30px')};
		line-height: ${between('26px', '40px')};
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: 'Playfair Display', serif;
		font-weight: 700;

		& + * {
			margin-bottom: 0;
			margin-top: -10px;
		}
	}

	ul,
	ol {
		padding-left: 20px;
		margin-bottom: 35px;

		li {
			margin-top: 10px;
		}
	}
`;

class PostPage extends React.Component {
	constructor(props) {
		super(props);

		if (typeof window !== 'undefined' && window && window.localStorage) {
			const slug = `${props.data.mysqlPost.category_slug}/${props.data.mysqlPost.slug}`;
			window.addEventListener('beforeunload', function (e) {
				e.preventDefault();
				window.localStorage.setItem('getPostFromAPI', slug);
				delete e['returnValue'];
			});

			const getPostFromAPI = window.localStorage.getItem('getPostFromAPI');
			if (getPostFromAPI === slug) {
				console.log('request api!');
			}
		}

		const post = props.data.mysqlPost;
		const photos = post.photos.split(', ');
		let seoDescription = html2text(post.description).replace(/(\r\n|\n|\r)/gm, '');
		const seoDescriptionMaxLength = 155;
		if (seoDescription.length > seoDescriptionMaxLength) seoDescription = `${seoDescription.substr(0, seoDescriptionMaxLength)}...`;

		this.state = {
			post: props.data.mysqlPost,
			photos: photos,
			seoDescription: seoDescription,
			canonical: props.pageContext.canonical,
		};
	}

	render() {
		return (
			<Page>
				<SEO title={this.state.post.title} description={this.state.seoDescription} image={'https://res.cloudinary.com/weselnicy/image/upload/w_1200,q_30/' + this.state.photos[0]} canonical={this.state.canonical} />
				<StyledHeaderSection hero={'https://res.cloudinary.com/weselnicy/image/upload/w_1200,q_30/' + this.state.photos[0]}>
					<HeaderFront>
						<Navigation />
					</HeaderFront>
				</StyledHeaderSection>

				<Container>
					<Title>{this.state.post.title}</Title>

					<Wrapper>
						<Meta>
							<ul>
								<li>
									<span className="label">Miasto</span>
									<span className="value">
										<Link to={`/${this.state.post.category_slug}/${this.state.post.town_slug}/`}><b>{this.state.post.town_label}</b></Link>
										{this.state.post.radius === -1 &&
											<>
												&nbsp;+cała Polska
												<span className="hint" tooltip="Działamy na terenie całej Polski">
													<FaQuestionCircle />
												</span>
											</>
										}
										{this.state.post.radius > 0 &&
											<>
												&nbsp;+{this.state.post.radius}km
												<span className="hint" tooltip={'Działamy w promieniu ' + this.state.post.radius + 'km'}>
													<FaQuestionCircle />
												</span>
											</>
										}
									</span>
								</li>
								<li>
									<span className="label">Cena</span>
									<span className="value">
										{this.state.post.price_from === 1 && <small>od </small>}
										{this.state.post.price}zł
									</span>
								</li>
								<li>
									<span className="label">Imię / firma</span>
									<span className="value">{this.state.post.name}</span>
								</li>

								{this.state.post.phone &&
									<li>
										<span className="label">Telefon</span>
										<span className="value">
											{this.state.post.phone}
											<span className="hint" tooltip="Powiedz, że dzwonisz z portalu Weselnicy.pl">
												<FaQuestionCircle />
											</span>
										</span>
									</li>
								}

								{(this.state.post.messenger || this.state.post.whatsapp) &&
									<li>
										<span className="label">Wyślij wiadomość</span>
										<span className="value">
											{this.state.post.messenger &&
												<a href={'https://m.me/' + this.state.post.messenger + '?ref=weselnicy'} target="_blank" rel="noopener noreferrer" className="messenger" tooltip="Wyślij wiadomość przez Messengera">
													<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M12 0c-6.627 0-12 4.975-12 11.111 0 3.497 1.745 6.616 4.472 8.652v4.237l4.086-2.242c1.09.301 2.246.464 3.442.464 6.627 0 12-4.974 12-11.111 0-6.136-5.373-11.111-12-11.111zm1.193 14.963l-3.056-3.259-5.963 3.259 6.559-6.963 3.13 3.259 5.889-3.259-6.559 6.963z" /></svg>
												</a>
											}

											{this.state.post.whatsapp &&
												<a href={'https://wa.me/' + this.state.post.whatsapp + '?text=Dzie%C5%84%20dobry%2C%20pisz%C4%99%20ze%20strony%20Weselnicy.pl'} target="_blank" rel="noopener noreferrer" className="whatsapp" tooltip="Wyślij wiadomość przez Whatsappa">
													<FaWhatsapp />
												</a>
											}
										</span>
									</li>
								}
							</ul>
						</Meta>

						<Content>
							{this.state.photos.length > 0 &&
								<Gallery>
									{this.state.photos.length === 1 &&
										<div>
											<img src={'https://res.cloudinary.com/weselnicy/image/upload/if_w_lt_1200/$img_current/c_fill,g_auto,h_628,w_1200,e_blur:500,o_40,q_85/l_$img,h_480,w_720,c_fit/if_else,c_scale,w_1200,q_85/if_end/' + this.state.photos[0]} className="single" alt={this.state.post.title} loading="lazy" />
										</div>
									}

									{this.state.photos.length > 1 &&
										<Carousel infiniteLoop={true} useKeyboardArrows={true} dynamicHeight={true} emulateTouch={true} showStatus={false}>
											{this.state.photos.map(photo => (
												<div key={photo}>
													<img src={'https://res.cloudinary.com/weselnicy/image/upload/if_w_lt_1200/$img_current/c_fill,g_auto,h_628,w_1200,e_blur:500,o_40,q_85/l_$img,h_480,w_720,c_fit/if_else,c_scale,w_1200,q_85/if_end/' + photo} alt={this.state.post.tiitle} loading="lazy" />
												</div>
											))}
										</Carousel>
									}
								</Gallery>
							}

							<Description dangerouslySetInnerHTML={{
								__html: sanitizeHtml(this.state.post.description, {
									allowedTags: ['b', 'i', 'u', 'em', 'strong', 'p', 'a', 'ul', 'ol', 'li', 'h2', 'h3', 'h4', 'h5', 'h6'],
								})
							}}></Description>
						</Content>
					</Wrapper>
				</Container>
			</Page>
		);
	}
};

export default PostPage;

export const query = graphql`
	query PostPage($id: Int!) {
		mysqlPost(uid: {eq: $id}) {
			slug
			title
			category_slug
			town_slug
			town_label
			radius
			price
			price_from
			name
			phone
			phone_masked
			messenger
			whatsapp
			description
			photos
			verified
		}
	}
`;
